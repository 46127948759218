import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import PreviewCompatibleImage from "../Blog/PreviewCompatibleImage"
import aboutiImg from "../../assets/images/hyfen/about-pg-img.png"
import asteriskIcon from "../../assets/images/hyfen/asterisk-icon.png"
import BillTrestrail from "../../assets/images/hyfen/team/Bill_Trestrail.png"
import JackieZheng from "../../assets/images/hyfen/team/Jackie_Zheng.jpeg"
import JacqelineChong from "../../assets/images/hyfen/team/Jacqeline_Chong.png"
import KirstinSchneider from "../../assets/images/hyfen/team/Kirstin_Schneider.png"
import SarahBurvenich from "../../assets/images/hyfen/team/Sarah_Burvenich.jpg"
import ChrisHausler from "../../assets/images/hyfen/team/Chris_Hausler.jpeg"
import PeterDickinson from "../../assets/images/hyfen/team/Peter_Dickinson.jpeg"
import SallyClarke from "../../assets/images/hyfen/team/Sally_Clarke.jpeg"
import NicolaPastorello from "../../assets/images/hyfen/team/Nicola_Pastorello.jpeg"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { templateKey: { eq: "teams" } } }
      ) {
        totalCount
        edges {
          node {
            frontmatter {
              teams {
                title
                name
                description
                image {
                  childImageSharp {
                    gatsbyImageData(layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  console.log("DATA", data)
  return (
    <div className="about-page">
      <section className="section1 pb-100">
        <div className="container">
          <p className="text-1">
            Hyfen8 takes the guesswork out of goodwill, offers you new forms of
            comparability, quantifying the previously unmeasurable, in the midst
            of uncertainty and change.
          </p>
          <p className="text-1 pb-5">
            We believe that every business or organisation will last longer,
            have a more positive impact and improve its valuation by paying
            attention to the intangibles and predicting factors beyond financial
            results and balance sheets.
          </p>
          <h1 className="heading-2 pb-4">
            Plus, we have the data to prove it.
            <img
              src={asteriskIcon}
              alt="asterisk-icon"
              className="mt-n2 ml-2"
            />
          </h1>
          <p className="text-2 txt-link">
            <Link to="/framework-intangibles">
              Learn more about our intangibles framework
            </Link>
          </p>
        </div>
      </section>
      <img
        src={aboutiImg}
        className="d-block d-md-none w-100"
        alt="about-img"
      />
      <section className="section2">
        <div className="container">
          <div className="row m-0">
            <div className="col-12 col-md-6 px-0 pr-md-3 pl-md-0">
              <img
                src={aboutiImg}
                className="d-none d-md-block"
                alt="about-img"
              />
            </div>
            <div className="col-12 col-md-6 px-0 pr-lg-3 pl-lg-2">
              <h2 className="pb-3 pt-5 text-1">How we started</h2>
              <p className="text-4">
                We founded Hyfen8 early in one of the biggest shakeups of our
                age: the COVID19 pandemic. Intangibles were already a silent
                factor in outperformance. Now more than ever, your
                organisation’s success and sustainability hinge on its
                intangibles.{" "}
              </p>
              <p className="text-4 pb-4">
                With Hyfen8 you access granular data and insight, and translate
                this into measurable growth, improvement and value. Whether you
                are building a business plan, strategising for fast-paced growth
                or improved impact, seeking investment, planning an exit, or
                supporting clients to do all of the above, Hyfen8 helps you
                maximise every aspect of your business and its value.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="section3">
        <div className="container">
          <h2>Meet the team</h2>
          <div className="row">
            {data && data.allMarkdownRemark.totalCount > 0 ? (
              data.allMarkdownRemark.edges[0].node.frontmatter.teams.map(
                team => {
                  return (
                    <div className="col-12 col-sm-6 col-md-4 pt-5">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: team.image,
                          alt: "testimonial",
                          width:
                            team.image?.childImageSharp?.gatsbyImageData?.width,
                          height:
                            team.image?.childImageSharp?.gatsbyImageData
                              ?.height,
                        }}
                      />
                      <h5 className="pt-4 pb-3">
                        {team.title} — {team.name}
                      </h5>
                      <p className="text-3">{team.description}</p>
                    </div>
                  )
                }
              )
            ) : (
              <>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={KirstinSchneider} alt="testimonial" />
                  <h5 className="pt-4 pb-3">
                    Founder and CEO — Kirstin Schneider
                  </h5>
                  <p className="text-3">
                    Kirstin is a trusted Board and C-suite advisor in the growth
                    sector. She is an expert on creating value through
                    intangible asset growth. An experienced executive, SME owner
                    and business strategist, Kirstin is a Founder Institute
                    alumni and holds an Executive MBA.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={JacqelineChong} alt="testimonial" />
                  <h5 className="pt-4 pb-3">
                    Product Design &amp; UX — Jacqueline Chong
                  </h5>
                  <p className="text-3">
                    Design lead with proven track record establishing and
                    mentoring LEAN UX design teams, solving complex issues and
                    real customer pain points across multiple industries. Expert
                    in delivering best-in-class SaaS solutions using data driven
                    design.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={JackieZheng} alt="testimonial" />
                  <h5 className="pt-4 pb-3">Product Manager — Jackie Zheng</h5>
                  <p className="text-3">
                    Jackie brings curiosity and analytical diligence to develop
                    products that solve real customer problems. An experienced
                    product manager she is passionate about applying lean and
                    agile thinking to build-measure-learn. Jackie holds a
                    Masters of Commerce (Information Systems).
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={NicolaPastorello} alt="testimonial" />
                  <h5 className="pt-4 pb-3">
                    AI Technology &amp; Research — Nicola Pastorello
                  </h5>
                  <p className="text-3">
                    Nico nurtures data and software experts into teams that
                    tackle complex problems. He is the Global Lead of Advanced
                    Analytics at BlueScope and has worked with innovation labs
                    and start-ups on product, technology, data and AI/ML. Nico
                    holds a PhD in Astrophysics, and both Master's and
                    Bachelor's degrees in Astronomy.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={SarahBurvenich} alt="testimonial" />
                  <h5 className="pt-4 pb-3">Brand Lead — Sarah Bürvenich</h5>
                  <p className="text-3">
                    A multi-disciplinary designer and art director, Sarah has
                    experience across diverse sectors including fin-tech,
                    health, education and FMCG, working with clients such as
                    Bellroy and RMIT Online. A meticulous designer, she delivers
                    a smooth and enjoyable customer experience.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={SallyClarke} alt="testimonial" />
                  <h5 className="pt-4 pb-3">
                    Culture Custodian - Sally Clarke
                  </h5>
                  <p className="text-3">
                    Culture, wellbeing and burnout expert, author, researcher,
                    coach, and speaker. A former finance lawyer, now focused on
                    preventing burnout in organizations and helping people live
                    authentic, fulfilling lives. Australian born, now based in
                    the Netherlands. Co Director at Human Leaders.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={BillTrestrail} alt="testimonial" />
                  <h5 className="pt-4 pb-3">Board Advisor — Bill Trestrail</h5>
                  <p className="text-3">
                    Bill is an experienced international executive, Venture
                    Partner and tech entrepreneur. A trusted business advisor
                    for many growth businesses, he is passionate about the
                    potential of Australian AI. Bill proudly backs female-led
                    startups and has been heavily involved in SBE Australia.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={PeterDickinson} alt="testimonial" />
                  <h5 className="pt-4 pb-3">
                    Tech/Product Advisor - Peter Dickinson
                  </h5>
                  <p className="text-3">
                    An experienced CTO, Head of Product and VP Operations.
                    Having led and worked in SaaS and tech scale ups around the
                    world including in California, Peter has successfully
                    navigated growth, exits and tech builds at the intersection
                    of Data, Product and UX.
                  </p>
                </div>
                <div className="col-12 col-sm-6 col-md-4 pt-5">
                  <img src={ChrisHausler} alt="testimonial" />
                  <h5 className="pt-4 pb-3">Data/ML Advisor - Chris Hausler</h5>
                  <p className="text-3">
                    Chris is passionate about using machine learning to build
                    great products. He is Head of Machine Learning at Zendesk,
                    leading a global team of scientists and engineers to ship
                    features used by millions of people each day. Chris holds a
                    BIT in Software Engineering and a PhD in Computational
                    Neuroscience.
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <section className="section4 pt-4 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 pt-4">
              <h5>Join our Hyfen8 family</h5>
            </div>
            <div className="col-12 col-md-5 pt-4">
              <p className="text-3">
                We are always on the lookout for brilliant people with fresh
                ideas and a passion for growth, sustainable impact and the power
                of predictive analytics.
              </p>
            </div>
            <div className="col-12 col-md-4 pt-4">
              <p className="text-3">
                <a
                  href="https://www.linkedin.com/company/hyfen8"
                  rel="noreferrer"
                  target="_blank"
                >
                  Follow us on LinkedIn
                </a>{" "}
                for future opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default AboutPage
