import React from "react"
import ReactWOW from "react-wow"
const MobileBanner = () => {
  return (
    <div className="mobile-banner pt-5">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="about-pg-banner-content mobile-banner-content">
              <ReactWOW delay=".1s" animation="fadeInLeft">
                <h1 className="heading-1">
                  The Hyfen Eight<sup className="registered">&reg;</sup>{" "}
                  Framework codifies intangibles and predicts future business
                  performance, resilience and strategic success.
                </h1>

                <div className="py-4"></div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MobileBanner
